import { memo, useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Painel de controle components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Painel de controle examples
import DataTable from "examples/Tables/DataTable";

// Data
import { getArticles } from "api";
import { getBooks } from "api";
import { useNavigate } from "react-router-dom";
import { deleteBook } from "api";
import { deleteArticle } from "api";
import { Button } from "@mui/material";
import MDButton from "components/MDButton";

function Projects() {
  const [artigos, setArtigos] = useState([]);
  const [livros, setLivros] = useState([]);
  const router = useNavigate();
  const columns = [
    { Header: "Titulo", accessor: "titulo", width: "45%", align: "left" },
    { Header: "URL", accessor: "url", align: "center" },
    { Header: "Acoes", accessor: "acoes", align: "center"}
  ];
  const columnsLivros = [
    { Header: "Titulo", accessor: "titulo", width: "45%", align: "left" },
    { Header: "Descrição", accessor: "descricao", align: "left" },
    { Header: "Url da Imagem", accessor: "imgUrl", align: "left" },
    { Header: "Link Direto", accessor: "urlDirect", align: "left" },
    { Header: "Amazon", accessor: "urlAmazon", align: "left" },
    { Header: "Google Books", accessor: "urlGoogle", align: "left" },
    { Header: "Data de Publicação", accessor: "data", align: "left" },
    { Header: "Acoes", accessor: "acoes", align: "center"}
  ];



  useEffect(() => {
    async function init() {
      const artigosList = await getArticles();

      // add actction button to each row
      artigosList.forEach((artigo) => {
        artigo.acoes = (
          <MDBox display="flex" justifyContent="center">
            <MDBox

              component="a"

              href={"/editar-artigo/" + artigo.id}
              color="primary.main"
              sx={{
                cursor: "pointer",
                marginRight: "0.5rem",
              }}
            >
              <Icon fontSize="small">open_in_new</Icon>
            </MDBox>
            <MDButton
              onClick={() => {

                deleteArticle(artigo.id);
                // refresh page
                //router("/dashboard");
              }}
              color="error.main"
              sx={{
                cursor: "pointer",
              }}
            >
              <Icon fontSize="small">delete</Icon>

            </MDButton>
          </MDBox>
        );
      });

      setArtigos(artigosList);
      const livrosList = await getBooks();
      // add actction button to each row
      livrosList.forEach((livro) => {
        livro.acoes = (
          <MDBox display="flex" justifyContent="center">
            <MDBox

              component="a"

              href={"/editar-livro/" + livro.id}
              color="primary.main"
              sx={{
                cursor: "pointer",
                marginRight: "0.5rem",
              }}
            >
              <Icon fontSize="small">open_in_new</Icon>
            </MDBox>
            <MDButton
              onClick={() => {
                console.log("delete ", livro.id)
                deleteBook(livro.id);
                // refresh page
                //router("/dashboard");
              }}
              color="error.main"
              sx={{
                cursor: "pointer",
              }}
            >
              <Icon fontSize="small">delete</Icon>

            </MDButton>
          </MDBox>
        );
      });
      setLivros(livrosList);
      console.log(artigos, artigosList);
      console.log(livros, livrosList);
    }
    init();
  }, []);

  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Artigos
          </MDTypography>
        </MDBox>
        <MDBox color="text" px={2}>
          <Icon
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            fontSize="small"
            onClick={openMenu}
          >
            more_vert
          </Icon>
        </MDBox>
        {renderMenu}
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows: artigos }}
          showTotalEntries={true}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />

        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          <MDBox>
            <MDTypography variant="h6" gutterBottom>
              Livros
            </MDTypography>
          </MDBox>
          <MDBox color="text" px={2}>
            <Icon
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              fontSize="small"
              onClick={openMenu}
            >
              more_vert
            </Icon>
          </MDBox>
          {renderMenu}
        </MDBox>

        <DataTable
          table={{ columns: columnsLivros, rows: livros }}
          showTotalEntries={true}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
    </Card>
  );
}

export default memo(Projects);
