// Import the functions you need from the SDKs you need
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { getDocs, getFirestore, collection, getDoc, doc, updateDoc, deleteDoc, addDoc} from "firebase/firestore";
import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyBkS74pcKDsxD5Fc55B7TJfKGBpG_47jaw",
  authDomain: "deabreu-88426.firebaseapp.com",
  projectId: "deabreu-88426",
  storageBucket: "deabreu-88426.appspot.com",
  messagingSenderId: "843139023953",
  appId: "1:843139023953:web:9816aa3c2754c75b1e2470"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

async function saveArticle(data) {
  console.log(data);
  try {
  await addDoc(collection(db, "artigos"), data);
  } catch (error) {
    console.log(error);
  }
}

async function editArticle(data, id) {
  try {
    const docRef = await doc(db, "artigos", id);
    await updateDoc(docRef, data);
  } catch (error) {
    console.log(error);
  }
}

async function getArticles() {
  try {
    const snap = await getDocs(collection(db, "artigos"));
    console.log("snap getArticles ", snap)
    const list = [];
    snap.docs.map((docs) => {
      let item = docs.data();
      item.id = docs.id;
      list.push(item);
    });
    return list;
  } catch (error) {
    console.log(error);
  }
}
async function saveBook(data) {
  try {
    await addDoc(collection(db, "livros"),data);
  } catch (error) {
    console.log(error);
  }
}

async function getArticle(id) {
  try {
    const docSnap = await getDoc(doc(db, "artigos", id));
    console.log("snap getArticle ", docSnap)
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }

  } catch (error) {
    console.log(error);
  }
}

async function deleteArticle(id) {
  try {
    const docRef = await doc(db, "artigos", id);
    await deleteDoc(docRef);
  } catch (error) {
    console.log(error);
  }
}

async function getBooks() {
  try {
    const snap = await getDocs(collection(db, "livros"));
    const list = [];
    snap.docs.map((docs) => {
      let item = docs.data();
      item.id = docs.id;
      list.push(item);
    });
    return list;
  } catch (error) {
    console.log(error);
  }
}

async function editBook(id, data) {
  try {
    const docRef = await doc(db, "livros", id);
    await updateDoc(docRef, data);
  } catch (error) {
    console.log(error);
  }
}

async function getBook(id) {
  try {
    const docSnap = await getDoc(doc(db, "livros", id));
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }

  } catch (error) {
    console.log(error);
  }
}

async function deleteBook(id) {
  try {
    const docRef = await doc(db, "livros", id);
    await deleteDoc(docRef);
  } catch (error) {
    console.log(error);
  }
}

async function loggedUser() {
  try {
    const user = await auth.currentUser;
    if (user) {
      return true;
    } else {
      return false;
    }
  }
  catch (error) {
    console.log(error);
    return false;
  }
}

async function getUser() {
  //await signOut(auth)
  // use onAuthStateChanged to get the user and return on promisse
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(user);
      } else {
        reject(null);
      }
    });
  }
  );
}

async function login(email, password) {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export { saveArticle, getArticles, saveBook, getBooks, loggedUser, login, getUser, editArticle, getArticle, editBook, getBook, deleteArticle, deleteBook };
