import React, { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Painel de controle components
import MDBox from "components/MDBox";

// Painel de controle example components
import Sidenav from "examples/Sidenav";

// Painel de controle themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Painel de controle Dark Mode themes
import themeDark from "assets/theme-dark";

// Painel de controle routes
import routes from "routes";

// Painel de controle contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { getUser } from "api";

function ProtectedRoute({ loggeed, children }) {
  console.log("loggeed", loggeed);
  if (!loggeed) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

function useUserLogged() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function checkLogged() {
      try {
        const userLogged = await getUser();
        setUser(userLogged);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
    checkLogged();
  }, []);

  useEffect(() => {
    console.log("userLogged hook ", user);
    console.log("loading hook ", loading);
    console.log("error hook ", error);
  }, [user, loading, error]);

  return { user, loading, error };
}


export const AuthContext = React.createContext(null);

export default function App() {
  const [loggeed, setLogged] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const { loading: loadingLogged, user } = useUserLogged();

  useEffect(() => {
    async function checkLogged() {
      setLogged(user ? true : false);
      console.log("user", user);
      console.log("loadingLogged", loadingLogged);
    }
    checkLogged();
  }, [loadingLogged, loggeed, user]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.key !== "login") {
          return (
            <Route
              exact
              path={route.route}
              element={
                <ProtectedRoute loggeed={loggeed}>
                  {route.component}
                </ProtectedRoute>
              }
              key={route.key}
            />
          );
        } else {
          return (
            <Route
              exact
              path={route.route}
              element={route.component}
              key={route.key}
            />
          );
        }
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );


  return (
    <>
      {loadingLogged ? (
        <React.Fragment>
          <h1>aguarde....</h1>
        </React.Fragment>
      ) : (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={
                  (transparentSidenav && !darkMode) || whiteSidenav
                    ? brandDark
                    : brandWhite
                }
                brandName="Painel de controle"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              {configsButton}
            </>
          )}
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </ThemeProvider>
      )}
    </>
  );
}
