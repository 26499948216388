// @mui material components
import Card from "@mui/material/Card";

// Painel de controle components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Painel de controle example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import { editArticle, saveArticle, getArticle } from "../../api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function CadastrarArtigos() {
  const [data, setData] = useState({
    titulo: "",
    url: "",
  });

  const [loading, setLoading] = useState(false);
  // get data from form url
  const params = useParams();


  useEffect(() => {
    async function init() {
      if (params.id) {
        const artigo = await getArticle(params.id);
        console.log("artigo", artigo);
        setData({
          titulo: artigo.titulo,
          url: artigo.url,
        });
      }
    }
    init();
  }, [params.id]);

  function changeInput(e) {
    console.log(e.target.name, e.target.value);
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={10}
          p={2}
          mb={10}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {params.id ? "Editar Artigo" : "Cadastrar Artigo"}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                onChange={changeInput}
                value={data.titulo}
                name="titulo"
                type="text"
                label="Titulo"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                onChange={changeInput}
                value={data.url}
                name="url"
                type="text"
                label="Link"
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                onClick={async () => {
                  setLoading(true);
                  if (params.id) {
                    await editArticle( data, params.id);
                    setLoading(false);
                  } else {
                    await saveArticle(data);
                    setLoading(false);
                  }
                }}
                variant="gradient"
                color="info"
                fullWidth
              >
                {
                  // if loading is true, show loading icon
                  loading ? (
                    <MDBox
                      component="span"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width="1.5rem"
                      height="1.5rem"
                    >
                      <MDBox
                        component="span"
                        display="inline-block"
                        width="1.5rem"
                        height="1.5rem"
                        borderRadius="50%"
                        border="2px solid"
                        borderColor="white"
                        borderTopColor="transparent"
                        animation="spin 1s linear infinite"
                      />
                    </MDBox>
                  ) : (
                    // if loading is false, show button text
                    <>{params.id ? "Editar Artigo" : "Cadastrar Artigo"}</>
                  )
                }
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default CadastrarArtigos;
