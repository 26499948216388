// @mui material components
import Card from "@mui/material/Card";

// react-router-dom components

// Painel de controle components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Painel de controle example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import { editBook, getBook, saveBook } from "../../api";
import { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useParams } from "react-router-dom";

function CadastrarLivros() {
  const [data, setData] = useState({
    titulo: "",
    imgUrl: "",
    descricao: "",
    data: "",
    urlDirect: "",
    urlAmazon: "",
    urlGoogle: "",
  });

  const [sucessRegister, setSucessRegister] = useState(false);
  const [loading, setLoading] = useState(false);

  function changeInput(e) {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  }
  const params = useParams();

  useEffect(() => {
    async function init() {
      if (params.id) {
        const livro = await getBook(params.id);
        setData({
          titulo: livro.titulo,
          imgUrl: livro.imgUrl,
          descricao: livro.descricao,
          data: livro.data,
          urlDirect: livro.urlDirect,
          urlAmazon: livro.urlAmazon,
          urlGoogle: livro.urlGoogle,
        });
      }
    }
    init();
  }, [params.id]);

  return (
    <>
      <Snackbar open={sucessRegister} autoHideDuration={6000}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Cadastrado com sucesso!
        </Alert>
      </Snackbar>
      <DashboardLayout>
        <DashboardNavbar />
        <Card>
          {sucessRegister && (
            <Alert severity="success">Cadastrado com sucesso!</Alert>
          )}
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={10}
            p={2}
            mb={10}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              {params.id ? "Editar Livro" : "Cadastrar Livro"}
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  onChange={changeInput}
                  name="titulo"
                  type="text"
                  value={data.titulo}
                  label="Titulo"
                  fullWidth
                />
              </MDBox>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                onChange={changeInput}
                name="imgUrl"
                type="text"
                value={data.imgUrl}
                label="Url da Imagem"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                onChange={changeInput}
                name="descricao"
                value={data.descricao}
                type="text"
                label="Descrição"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                onChange={changeInput}
                name="data"
                value={data.data}
                type="date"
                label="Data de Publicação"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                onChange={changeInput}
                name="urlDirect"
                value={data.urlDirect}
                type="text"
                label="Link Direto"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                onChange={changeInput}
                name="urlAmazon"
                value={data.urlAmazon}
                type="text"
                label="Link Amazon"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                onChange={changeInput}
                name="urlGoogle"
                value={data.urlGoogle}
                type="text"
                label="Link Google"
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                disabled={loading}
                onClick={async () => {
                  setLoading(true);
                  if (params.id) {
                    await editBook(params.id, data);
                    setLoading(false);
                  } else {
                    await saveBook(data);
                    setSucessRegister(true);
                    setLoading(false);
                    setData({
                      titulo: "",
                      imgUrl: "",
                      descricao: "",
                      data: "",
                      urlDirect: "",
                      urlAmazon: "",
                      urlGoogle: "",
                    });
                  }
                }}
                variant="gradient"
                color="info"
                fullWidth
              >
                {
                  // if loading is true, show loading icon
                  loading ? (
                    <MDBox
                      component="span"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width="1.5rem"
                      height="1.5rem"
                    >
                      <MDBox
                        component="span"
                        display="inline-block"
                        width="1.5rem"
                        height="1.5rem"
                        borderRadius="50%"
                        border="2px solid"
                        borderColor="white"
                        borderTopColor="transparent"
                        animation="spin 1s linear infinite"
                      />
                    </MDBox>
                  ) : (
                    // if loading is false, show button text
                    <>{params.id ? "Editar Livro" : "Cadastrar Livro"}</>
                  )
                }
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default CadastrarLivros;
