/**
=========================================================
* Painel de controle - v2.1.0
=========================================================

* Product Page: https://www.bendevoficial.com/product/material-dashboard-react
* Copyright 2022 Bendev Junior (https://www.bendevoficial.com)

Coded by www.bendevoficial.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Painel de controle base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Painel de controle helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { white } = colors;
const { borderRadius } = borders;

const sidenav = {
  styleOverrides: {
    root: {
      width: pxToRem(250),
      whiteSpace: "nowrap",
      border: "none",
    },

    paper: {
      width: pxToRem(250),
      backgroundColor: white.main,
      height: `calc(100vh - ${pxToRem(32)})`,
      margin: pxToRem(16),
      borderRadius: borderRadius.xl,
      border: "none",
    },

    paperAnchorDockedLeft: {
      borderRight: "none",
    },
  },
};

export default sidenav;
