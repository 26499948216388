import Dashboard from "layouts/dashboard";
import CadastrarArtigos from "layouts/cadastrar-artigos";
import CadastrarLivros from "layouts/cadastrar-livros";

// @mui icons
import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Cadastrar Artigos",
    key: "cadastrar-artigos",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/cadastrar-artigos",
    component: <CadastrarArtigos />,
  },
  {
    type: "route",
    name: "Editar Artigos",
    key: "editar-artigo",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/editar-artigo/:id",
    component: <CadastrarArtigos />,
  },
  {
    type: "collapse",
    name: "Cadastrar Livros",
    key: "cadastrar-livros",
    icon: <Icon fontSize="small">book-view</Icon>,
    route: "/cadastrar-livros",
    component: <CadastrarLivros />,
  },
  {
    type: "route",
    name: "Editar livro",
    key: "editar-livro",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/editar-livro/:id",
    component: <CadastrarLivros />,
  },
  {
    type: "route",
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/login",
    component: <SignIn />,
  },
];

export default routes;
