/**
=========================================================
* Painel de controle - v2.1.0
=========================================================

* Product Page: https://www.bendevoficial.com/product/material-dashboard-react
* Copyright 2022 Bendev Junior (https://www.bendevoficial.com)

Coded by www.bendevoficial.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Painel de controle base styles
import borders from "assets/theme-dark/base/borders";
import colors from "assets/theme-dark/base/colors";

// Painel de controle helper functions
import pxToRem from "assets/theme-dark/functions/pxToRem";

const { borderWidth } = borders;
const { light } = colors;

const tableCell = {
  styleOverrides: {
    root: {
      padding: `${pxToRem(12)} ${pxToRem(16)}`,
      borderBottom: `${borderWidth[1]} solid ${light.main}`,
    },
  },
};

export default tableCell;
